<template>
  <div class="slider_circle">
    <a v-on:click="reduceCallbackPromise()"><span class="badge" :class="{ active: showPageNumber === 1 }"></span></a>
    <a v-on:click="callbackPromise()"><span class="badge" :class="{ active: showPageNumber === 2 }"></span></a>
  </div>
</template>

<script>
export default {
  props: ['showPageNumber', 'addCallback', 'reduceCallback'],
  data() {
    return {
      loading: false
    };
  },
  methods: {
    callbackPromise() {
      this.loading = true;
      var promise = this.addCallback.call();
      if (promise)
        promise.finally(() => {
          this.loading = false;
        });
      else this.loading = false;
    },
    reduceCallbackPromise() {
      this.reduceCallback.call();
    }
  }
};
</script>
