<template>
  <el-card class="progress">
    <div class="top">
      <div class="progress-circle">
        <svg class="progress-ring" viewBox="0 0 152 152">
          <circle
            class="progress-ring__circle"
            stroke="#3D526E"
            stroke-width="10"
            fill="transparent"
            transform="rotate(-90 77 73)"
            :r="circle.r"
            :cx="circle.cx"
            :cy="circle.cy"
          />
          <circle
            id="progress"
            class="progress-ring__circle"
            stroke="#06C806"
            stroke-width="10"
            fill="transparent"
            transform="rotate(-90 77 73)"
            :r="circle.r"
            :cx="circle.cx"
            :cy="circle.cy"
            :stroke-dasharray="circle.circumference + ' ' + circle.circumference"
            :style="{ strokeDashoffset }"
          />
        </svg>
        <p>{{ userPercentage }}%</p>
      </div>
      <span>{{ $t('register.progressStatus.title') }}</span>
    </div>
    <div class="line"></div>
    <div class="bottom">
      <div class="img"><img src="@/assets/images/register/time.png" alt="" /></div>
      <span>
        {{ $t('register.progressStatus.estimatedTimeTitle') }} <br />
        <b> {{ userProgress.minutes }} {{ $t('register.progressStatus.min') }} </b>
      </span>
    </div>
  </el-card>
</template>
<script>
export default {
  props: { userProgress: Object },
  data() {
    return {
      circle: {
        r: 67,
        cx: 72,
        cy: 72,
        circumference: 67 * 2 * Math.PI
      },
      myInterval: null,
      userPercentage: 0
    };
  },
  watch: {
    registerStep: {
      handler(number) {
        this.$nextTick(() => {
          this.myInterval = setInterval(
            function () {
              if (this.userPercentage >= this.userProgress.progress) clearInterval(this.myInterval);
              else this.userPercentage++;
            }.bind(this),
            50
          );
        });
      },
      immediate: true
    }
  },
  computed: {
    registerStep() {
      return this.$store.state.common.registerStep;
    },
    strokeDashoffset() {
      return this.circle.circumference - (this.userPercentage / 100) * this.circle.circumference;
    }
  }
};
</script>
