export default {
  data() {
    return {
      idPlayer: null,
      poaPlayer: null,
      idName: null,
      poaName: null,
      matches: null,
    }
  },
  mounted() {
    this.getIdPoaName()
  },
  computed: {
    lang() {
      return this.$store.state.common.lang
    },
    videoId() {
      switch (this.lang) {
        case 'en_US':
          return 'v23-BR27xJs'
        case 'zh_CN':
          return 'KcbWC5PEp0E'
        case 'fr_FR':
          return 'OIO1Z2nAvuo'
        case 'ko':
          return 'ONi032LmV9Q'
        case 'ja':
          return 'SYlYrgwv5XQ'
        default:
          return 'v23-BR27xJs'
      }
    },
    videoPoa() {
      switch (this.lang) {
        case 'en_US':
          return 'z-2uQF07Bcs'
        case 'zh_CN':
          return 'VQLXAgaPT00'
        case 'fr_FR':
          return '0pG-2ivjHGY'
        case 'ko':
          return 'm_ZwT207M70'
        case 'ja':
          return 'PHaoxP0q_tI'
        default:
          return 'z-2uQF07Bcs'
      }
    },
  },
  created() {
    window.addEventListener('resize', this.resizeHandler)
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    getIdPoaName() {
      const currMatches = window.matchMedia('(max-width: 1199px)').matches
      if (currMatches != this.matches) {
        this.matches = currMatches
        this.idPlayer = null
        this.poaPlayer = null
        this.idName = this.matches ? 'id-player-small' : 'id-player'
        this.poaName = this.matches ? 'poa-player-small' : 'poa-player'
      }
    },
    resizeHandler(e) {
      this.getIdPoaName()
    },
    onIdPlayerReady() {
      this.idPlayer = new YT.Player(this.idName, {
        videoId: this.videoId,
        events: {
          onReady: this.onPlayerReady,
        },
      })
    },
    onPoaPlayerReady() {
      this.poaPlayer = new YT.Player(this.poaName, {
        videoId: this.videoPoa,
        events: {
          onReady: this.onPlayerReady,
        },
      })
    },
    onPlayerReady(event) {
      event.target.playVideo()
    },
  },
}
