<template>
  <el-button class="bg-primary" v-on:click="callbackPromise()" :disabled="loading">
    <i class="el-icon-loading-small" v-show="loading"></i> {{ text }}
  </el-button>
</template>

<script>
export default {
  props: ['text', 'callback'],
  data() {
    return {
      loading: false
    };
  },
  methods: {
    callbackPromise() {
      this.loading = true;
      var promise = this.callback.call();
      if (promise)
        promise.finally(() => {
          this.loading = false;
        });
      else this.loading = false;
    }
  }
};
</script>
