<template>
  <el-card class="show-in-pad">
    <div class="title">{{ $t('register.progressStatus.title') }}</div>
    <div class="progressbar">
      <div :style="{ width: userPercentage + '%' }" :value="userPercentage">{{ userPercentage }} %</div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'Progress-pad',
  props: { userProgress: Object },
  data() {
    return {
      myInterval: null,
      userPercentage: 0
    };
  },
  watch: {
    registerStep: {
      handler(number) {
        this.$nextTick(() => {
          this.myInterval = setInterval(
            function () {
              this.userPercentage >= this.userProgress.progress
                ? clearInterval(this.myInterval)
                : this.userPercentage++;
            }.bind(this),
            50
          );
        });
      },
      immediate: true
    }
  },
  computed: {
    registerStep() {
      return this.$store.state.common.registerStep;
    }
  }
};
</script>

<style lang="scss" scoped>
.show-in-pad {
  display: none;
  border-radius: 8px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;

  .title {
    font-size: 23px;
    color: $primary;
    text-transform: uppercase;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 20px;
  }

  .progressbar {
    box-shadow: inset 0px 2px 1px 0px rgba(0, 0, 0, 0.004), 4px 4px 50px 0px rgba(0, 0, 0, 0.08);
    background-color: rgb(39, 52, 68);
    border-radius: 8px;
    padding: 3px;
    font-size: 16px;
    color: $primary;
    font-weight: bold;
    line-height: 25px;
    text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.19);
    text-align: center;

    > div {
      background-color: #06c709;
      height: 25px;
      border-radius: 8px 0 0 8px;
    }
  }
}
@media (max-width: 1199px) {
  .show-in-pad {
    display: block;
  }
}

@media (max-width: 550px) {
  .show-in-pad .title {
    font-size: 16px;
  }
}
</style>
