<template>
  <div id="register">
    <div class="content_box">
      <div class="inner_max">
        <el-row :gutter="20">
          <ProgressPad :userProgress="userProgress"></ProgressPad>
          <el-col :xs="24" :sm="24" :md="24" :lg="19" class="form_box">
            <div class="tab">
              <div class="tab_item" :class="{ active: showPage.id == 1 || showPage.id == 2, finish: registerStep > 2 }">
                <p>{{ this.$t('register.tabs.personalDetails') }}</p>
              </div>
              <div class="tab_item" :class="{ active: showPage.id == 3, finish: registerStep > 3 }">
                <p>{{ this.$t('register.tabs.moreAboutYou') }}</p>
              </div>
              <div class="tab_item" :class="{ active: showPage.id == 4, finish: registerStep > 4 }">
                <p>{{ this.$t('register.tabs.accountConfig') }}</p>
              </div>
              <div
                class="tab_item"
                :class="{ active: showPage.id == 5, finish: registerStep > 5, btn_ru: lang === 'ru' }"
              >
                <p>{{ this.$t('register.tabs.confirmYourId') }}</p>
              </div>
              <div class="tab_item">
                <p>{{ this.$t('register.tabs.fundYourAcc') }}</p>
              </div>
            </div>
            <el-card class="main">
              <h3 class="register-title">{{ showPage.name }}</h3>
              <component
                ref="page"
                :is="showPage.component"
                :showPageNumber="showPageNumber"
                @addPage="addPage"
                @skipNextStep="skipNextStep"
              ></component>
              <CircleButton
                v-if="showPageNumber === 1 || showPageNumber === 2"
                :showPageNumber.sync="showPageNumber"
                :addCallback="submit"
                :reduceCallback="reducePage"
              ></CircleButton>
              <div class="bottom">
                <el-button v-if="showPageNumber > 2" @click="reducePage" data-testid="back">
                  {{ this.$t('register.btn.back') }}
                </el-button>
                <LoadingButton
                  :text="$t('register.btn.next')"
                  :callback="submit"
                  :class="{ long_button: showPageNumber === 1 || showPageNumber === 2 }"
                  data-testid="next"
                ></LoadingButton>
              </div>
            </el-card>
          </el-col>
          <el-col :lg="5" class="progress_box">
            <Progress :userProgress="userProgress"></Progress>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import PersonalDetails from '@/components/register/PersonalDetails';
import MoreAboutYou from '@/components/register/MoreAboutYou';
import AccountConfiguration from '@/components/register/AccountConfiguration';
import ConfirmID from '@/components/register/ConfirmID';
import Progress from '@/components/register/Progress';
import ProgressPad from '@/components/register/Progress-pad';
import LoadingButton from '@/components/LoadingButton';
import CircleButton from '@/components/CircleButton';
import youTube from '@/mixins/youTube';
import registerMixins from '@/mixins/register';

export default {
  name: 'DefaultRegister',
  data() {
    return {
      progressList: [
        {
          name: this.$t('register.tabs.personalDetails'),
          id: 1,
          progress: 25,
          minutes: 5,
          component: 'PersonalDetails',
          gtmEvent: 'PersonalDetails1'
        },
        {
          name: this.$t('register.personalDetails.page2.pageTitle'),
          id: 2,
          progress: 35,
          minutes: 4,
          component: 'PersonalDetails',
          gtmEvent: 'PersonalDetails2'
        },
        {
          name: this.$t('register.moreAboutYou.pageTitle1'),
          id: 3,
          progress: 55,
          minutes: 3,
          component: 'MoreAboutYou',
          gtmEvent: 'MoreAboutYou'
        },
        {
          name: this.$t('register.tabs.accountConfig'),
          id: 4,
          progress: 80,
          minutes: 2,
          component: 'AccountConfiguration',
          gtmEvent: 'AccountConfig'
        },
        {
          name: this.$t('register.tabs.confirmYourId'),
          id: 5,
          progress: 90,
          minutes: 1,
          component: 'ConfirmID',
          gtmEvent: 'ConfirmID'
        },
        {
          id: 6,
          progress: 100,
          name: this.$t('register.tabs.fundYourAcc')
        }
      ]
    };
  },
  mixins: [youTube, registerMixins],
  components: {
    PersonalDetails,
    MoreAboutYou,
    AccountConfiguration,
    ConfirmID,
    Progress,
    ProgressPad,
    LoadingButton,
    CircleButton
  },
  provide() {
    return {
      onIdPlayerReady: this.onIdPlayerReady,
      onPoaPlayerReady: this.onPoaPlayerReady
    };
  },
  computed: {
    showYoutube() {
      return !location.hostname.includes('.hk');
    },
    videoComponent() {
      return () => this.$platform.componentLoader(this.regulator, 'register/VideoTemplate', true).component;
    },
    lang() {
      return this.$store.state.common.lang;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/register/register.scss';
</style>
